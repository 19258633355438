<template>
  <div class="dashboard">
    <el-tabs v-model="activeName">
      <el-tab-pane label="JETree" name="jeTree">
        <JETree :isForAdmin="true" />
      </el-tab-pane>
      <el-tab-pane label="Report" name="report">
        <table class="dtable">
          <tr>
            <td style="width: 50%; vertical-align: top">
              <el-table :data="tableData" style="width: 100%">
                <el-table-column prop="name" label="Item" />
                <el-table-column prop="value" label="Value" :width="this.$widthRatio * 180 + 'px'" />
              </el-table>
              <div style="margin-top: 20px">
                <el-button @click="loadSystemStats(true, true)"> Refresh </el-button>
              </div>
            </td>
            <td></td>
          </tr>
        </table>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { getData } from "../../service/api";
import JETree from "../common/JETree.vue";

export default {
  name: "dashboard",
  components: { JETree },
  data() {
    return {
      activeName: "jeTree",
      tableData: [],
    };
  },
  computed: {},
  created() {
    this.initAndLoadData();
  },
  methods: {
    initAndLoadData() {
      this.loadSystemStats(false, true);
    },
    loadSystemStats(refresh, showGlobalLoading) {
      getData(
        `common/getSystemStatistic`,
        { refresh: refresh },
        {
          isShowLoading: showGlobalLoading,
        }
      ).then((res) => {
        if (res.result && res.code === "200") {
          this.tableData = res.result;
        }
      });
    },
  },
};
</script>
<style scoped>
.dashboard .subject {
  height: 38px;
  line-height: 38px;
  color: #3f9dff;
  text-align: center;
  border-bottom: solid 2px #e4e7ed;
  font-weight: 400;
}
.dashboard .dtable {
  display: block;
  min-width: 100%;
  word-wrap: break-word;
  display: table;
}
</style>
